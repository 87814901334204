import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import LayoutContextWrapper from "./src/state/context/layout-context-wrapper"

import "typeface-inter"

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={process.env.GATSBY_RECAPTCHA_KEY}>
      <LayoutContextWrapper>{element}</LayoutContextWrapper>
    </GoogleReCaptchaProvider>
  )
}
