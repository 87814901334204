import { TOGGLELAYOUT } from "../constants"

export const initialState = "masonry"

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLELAYOUT:
      return action.layoutType
    default:
      return state
  }
}

export default layoutReducer
