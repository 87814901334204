import React, { useReducer } from "react"
import LayoutContext from "./layout-context"
import layoutReducer, { initialState } from "../reducers/layout-reducer"
import { toggleLayoutAction } from "../actions/layout-actions"

const LayoutContextWrapper = ({ children }) => {
  const [layoutType, layoutDispatch] = useReducer(layoutReducer, initialState)
  const toggleLayout = type => {
    if (type === layoutType) {
      return
    }
    layoutDispatch(toggleLayoutAction(type))
  }

  return (
    <LayoutContext.Provider value={{ layoutType, toggleLayout }}>
      {children}
    </LayoutContext.Provider>
  )
}

export default LayoutContextWrapper
