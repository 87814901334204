module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-preact/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Stimpack","short_name":"Stimpack","lang":"en","description":"Healthy supplements that taste great, help you perform your best, and feel your greatest. Powered by Beyond™. Go Harder. Focus Longer. Achieve Greatness.","start_url":"/","background_color":"#48c3ce","theme_color":"#48c3ce","display":"standalone","icon":"src/images/stimpack-icon.png","icon_options":{"purpose":"any maskable"},"cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"7cac251676e37fe09f0c46e3bb207547"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"e9YU0Ufb7xHxmPFKwzE5CJZszUG69ck3","devKey":"yZbSHBkpHwMv4Q6UmPPQiptWcISPvhxa","trackPage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
